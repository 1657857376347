



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.upload-btn {
  width: 5rem;
  height: 28px;
  margin-top: 15px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
.el-collapse-item__header {
  height: 21px;
  line-height: 21px;
  background: none;
  .el-collapse-item__content {
    margin-bottom: 10px !important;
  }
}
.el-collapse-item__wrap {
  background: none;
}
.remind {
  .el-form-item__content {
    line-height: 1.5rem;
    .colorkey {
      color: tomato;
    }
  }
}
.rejectBox {
  display: flex;
  align-items: center;
  span {
    padding-left: 0.25rem;
    font-size: 0.9rem;
  }
}
.el_CardRed {
  background-color: #faebeb;
  color: #f36464;
  border: 1px solid #faebeb;
}
.el_CardGreen {
  background-color: #eaf7f4;
  color: #5dd9b9;
  border: 1px solid #eaf7f4;
}
.el_CardBlue {
  background-color: #eaeffb;
  color: #598efe;
  border: 1px solid #eaeffb;
}
.bgc-redd:hover,
.bgc-redd:focus,
.bgc-redd:disabled,
.bgc-redd:disabled:hover {
  background-color: #e33a1d;
  border-color: #e33a1d;
  color: #fff;
}

.bgc-blued:hover,
.bgc-blued:focus,
.bgc-blued:disabled,
.bgc-blued:disabled:hover {
  background-color: #248bff;
  border-color: #248bff;
  color: #fff;
}
.bgc-green:hover,
.bgc-green:focus,
.bgc-green:disabled,
.bgc-green:disabled:hover {
  background-color: #08c6a2;
  border-color: #08c6a2;
  color: #fff;
}
